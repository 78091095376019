.col-2 .cookies,
.col-2 .details {
  margin: 16px;
}

.col-2 .cookies-details {
  margin-top: 35px;
}

.col-2 .cookies-details .cookies {
  justify-content: center;
}

.col-2 .social-links {
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;
}

.right {
  margin-left: 109px;
}

.right .store {
  margin-top: 10px;
}

@media (max-width: 1400px) {
  .col-2 {
    order: -1;
    padding-right: 88px;
  }

  .col-2 .cookies,
  .col-2 .details {
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .col-2 .cookies-details {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
  }

  .col-2 .cookies-details a {
    margin-bottom: 20px;
  }

  .col-2 .cookies-details .cookies,
  .col-2 .cookies-details .details {
    margin: 0;
  }
  .col-2 .cookies {
    order: 2;
    flex-wrap: wrap;
  }

  .col-2 .social-links {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 65px;
  }
}
