.logo {
  height: 29px;
  width: 74px;
  cursor: pointer;
}

.nav {
  display: flex;
}

.nav-left-logo {
  flex: 1;
  max-width: max-content;
}

.nav-left-content {
  text-align: left;
  flex: 1.5;
}

.nav-right-tabs {
  text-align: right;
  flex: 4.5;
}

.nav-right-divider {
  flex: 0.1;
}

.nav-right-auth {
  flex: 2.9;
}

.logo-mobile {
  height: 29px;
  width: 74px;
  margin: 13px auto;
}

.share-icon {
  padding-left: 5px;
}

.caret-down-icon {
  padding-left: 6.6px;
}

@media (max-width: 1130px) {
  .nav-left-content {
    display: none;
  }
}
@media (max-width: 900px) {
  .nav {
    display: block;
  }
  .nav-mobile-logo {
    text-align: center;
  }
}
