#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 255px; /* Footer height */
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 255px; /* Footer height */
}

@media only screen and (max-width: 1400px) {
  #content-wrap {
    padding-bottom: 730px; /* Footer height */
  }

  #footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 730px; /* Footer height */
    margin: 0;
  }
}
